import React, {
  Suspense,
  useCallback, useEffect, useRef, useState,
} from 'react';

import {
  LeftOutlined,
  EyeInvisibleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { Col, Row } from 'antd/es/grid';

import { Button } from 'antd';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { passwordReset, passwordResetConfirm } from '../action-creators';
import { useTranslation } from 'react-i18next';
import CircularProgress from '../../../components_base/CircularProgress';
import { CustomField } from '../../../components/CustomField';
import LogoIcon from "../../../assets/images/logo_auth.png"
import LogoWinterIcon from "../../../assets/navbar/logo_winter.png";

function UserForgotPassword() {
  const { t } = useTranslation('user');
  const dispatch = useDispatch();
  const [currentState, setCurrentState] = useState('phone');
  const [canSendNewCode, setCanSendNewCode] = useState(true);
  const [phone, setPhone] = useState('');
  const formRef = useRef(null);

  const [isPhone, setIsPhone] = useState(true);
  const [isPassword, setIsPassword] = useState(true);
  const [timeCounter, setTimeCounter] = useState(59);
  const [isCounterCompleted, setIsCounterCompleted] = useState(false);
  const [isCodeQuestion, setIsCodeQuestion] = useState(false);
  const [isNewTimer, setIsNewTimer] = useState(true)

  useEffect(() => {
    let interval;
    if (currentState === "code" && isNewTimer) {
      interval = setInterval(() => {
        setTimeCounter((prevState) => {
          if (prevState > 0) {
            return prevState - 1
          }
          clearInterval(interval);
          setIsNewTimer(false);
          setIsCounterCompleted(true);
          return prevState
        })
      }, 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [currentState, isNewTimer])

  const handleRegister = useCallback(
    (values, actions) => {
      if (currentState === 'phone') {
        dispatch(passwordReset(values)).then(
          () => {
            actions.setSubmitting(false);
            setCurrentState('code');
            setCanSendNewCode(false);
          },
          ({ phone, username }) => {
            actions.setErrors({ username: username ?? phone });
            actions.setSubmitting(false);
          },
        );
      } else if (currentState === 'code') {
        const regex = /^(?=.*[0-9])(?=.*[A-Za-z])[A-Za-z0-9]+$/;
        if (values.password && !(values.password.length >= 8 && regex.test(values.password))) {
          actions.setErrors({'password': 'Убедитесь, что в пароле минимум 8 символов, среди которых есть буквы и цифры'});
          actions.setSubmitting(false);
          return;
        }
        dispatch(passwordResetConfirm(values)).then(
          () => {
            actions.setSubmitting(false);
          },
          (errors) => {
            actions.setSubmitting(false);
            actions.setErrors(errors);
          },
        );
      }
    },
    [currentState],
  );


  const getNewCode = () => {
    dispatch(passwordReset({ phone })).then(
      () => {
        setCanSendNewCode(false);
      },
      (errors) => {
        if (formRef.current) formRef.current.setErrors(errors);
      },
    );
  };

  useEffect(() => {
    if (!canSendNewCode) setTimeout(() => setCanSendNewCode(true), 30000);
  }, [canSendNewCode]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-login-content custom-login-form">
          <Link to="/login" className="custom-login-form__back">
            <LeftOutlined className="login-back" />
            Назад
          </Link>

          <Formik initialValues={{}} onSubmit={handleRegister} innerRef={formRef}>
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <div className="login-header gx-w-100 gx-d-flex gx-justify-content-start gx-align-items-center">
                    <img src={LogoIcon} alt="logo" className="login-header__logo"/>
                    <div className="login-header__text gx-font-weight-semi-bold gx-text-uppercase">id</div>
                  </div>
                  <Row>
                    <Col xs={24}>
                      <div className="custom-login-form__title">{t('password_recovery')}</div>
                    </Col>
                    <Col xs={24}>
                      <div className="custom-login-form__description">{t('auth_code')}</div>
                    </Col>
                    <Col xs={24}>
                      <Field
                        name="username"
                        type="text"
                        component={CustomField}
                        label={isPhone ? t('placeholder.phone_auth') : t('placeholder.email_auth')}
                        onRecoveryChange={setPhone}
                        isPhone={isPhone}
                        isAutoFocus
                      />
                    </Col>
                    {currentState === 'code' && (
                      <>
                        <Col xs={24}>
                          <Field
                            name="code"
                            type="text"
                            label={t('label.code')}
                            component={CustomField}
                          />
                          <Field
                            name="password"
                            type={isPassword ? "password" : "text"}
                            label={t('placeholder.new_password')}
                            component={CustomField}
                            suffix={(
                              <div 
                                role="presentation" 
                                className="gx-pointer custom-login-form__suffix"
                                onClick={() => {
                                  setIsPassword(!isPassword)
                                }}>
                                {isPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                              </div>
                            )}
                          />
                        </Col>
                        <Col xs={24} className="gx-d-flex gx-align-items-center gx-justify-content-center custom-login-form__code-setts">
                          {
                            timeCounter 
                            ? 
                            (
                              <div className="custom-login-form__counter gx-text-center">
                                {t('auth_code_resend')} 00:{`${timeCounter}`.length > 1 ? `${timeCounter}` : `0${timeCounter}`}
                              </div>
                            )
                            :
                            (
                              <button 
                                className="gx-text-center custom-login-form__button-as-link"
                                type="button"
                                onClick={() => {
                                  setTimeCounter(59);
                                  setIsNewTimer(true);
                                  getNewCode();
                                }}>
                                  {t('resend_sms')}
                              </button>
                            )
                          }
                        </Col>
                      </>
                    )}

                    <Col xs={24} className="gx-text-center">
                      <Button type="primary" htmlType="submit" loading={isSubmitting} className="gx-w-100 custom-login-form__submit">{currentState === 'phone' ? t('send_code') : t('save')}</Button>
                    </Col>
                    {
                      isCounterCompleted
                      ?
                      <Col xs={24} className="gx-flex-column gx-align-items-center gx-justify-content-center custom-login-form__bottom">
                        {
                          isCodeQuestion
                          ?
                          (
                            <div className="custom-login-form__counter gx-text-center">{t('code_call_us')}</div>
                          )
                          :
                          <button 
                            className="gx-text-center custom-login-form__button-as-link"
                            type="button"
                            onClick={() => setIsCodeQuestion(true)}>
                              {t('sms_not_coming')}
                          </button>
                        }
                      </Col>
                      :
                      null
                    }
                    <Col xs={24} className="gx-flex-column gx-align-items-center gx-justify-content-center custom-login-form__bottom">
                      <button 
                        className="gx-text-center custom-login-form__button-as-link"
                        type="button"
                        onClick={() => {
                          setIsPhone(!isPhone);
                          setCurrentState("phone");
                          setCanSendNewCode(true);
                          setFieldValue("username", "")
                        }}>
                          {isPhone ? t('recover_with_email') : t('recover_with_phone')}
                        </button>
                    </Col>
                  </Row>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export const SuspendedUserForgotPassword = () => <Suspense fallback={<CircularProgress />}><UserForgotPassword /></Suspense>

export default UserForgotPassword;

import React from 'react';
import Badge from 'antd/es/badge';
import { useTranslation } from 'react-i18next';
import useSelector from '../../util/hooks/useSelector';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';

function Bonus() {
  const { currentBalance } = useSelector((state) => state.user);

  return (
    <Link to="/bonuses" className="gs-d-flex gx-flex-row gx-align-items-center custom-navbar__bonus">
      <div className="gx-align-items-center">
        <Tooltip placement="bottom" title={<span className="gx-fs-md gx-p-2">Бонусы</span>}>
          <Badge
            className="gx-mt-1 bonus-badge gx-mr-1"
            count={currentBalance.bonusBalance}
            overflowCount={99999999}
            showZero
            style={{ background: '#1B2B49' }}
          />
        </Tooltip>

        {!!currentBalance.bonusHoldBalance &&
          <Tooltip placement="bottom" title={<span className="gx-fs-md gx-p-2">Ожидается к начислению до 10 числа</span>}>
            <Badge
              className="gx-mt-1 bonus-badge"
              count={currentBalance.bonusHoldBalance}
              overflowCount={99999999}
              showZero
              style={{ background: '#fc2c2c' }}
            />
          </Tooltip>
           || null}
      </div>
    </Link>
  );
}

export default Bonus;

import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import InputMask from "react-input-mask";

import classNames from 'classnames';
import { Select } from 'antd';

const CustomField = ({
    field,
    form: { setFieldError, handleChange, setFieldTouched, errors, touched },
    ...props
  }: FieldProps & { 
    label: string, 
    suffix?: React.ReactNode, 
    isPhone?: boolean, 
    type: string, 
    onRecoveryChange?: (value: string) => React.Dispatch<React.SetStateAction<string>>, 
    errorName?: string, 
    disableTouch?: boolean, 
    isAutoFocus?: boolean, 
    maxLength?: number, 
    handleFocusField?: () => void,
    isSelect?: boolean,
    opts?: any }) => {

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const { label, type } = props;

    const onChange = (e: React.ChangeEvent) => {
        if (typeof props.onRecoveryChange === "function") {
            const target = e.target as HTMLInputElement;
            props.onRecoveryChange(target.value)
        }
        handleChange(e)
    }

    const getCurrentField = () => {
        if (props.isPhone) {
            return (
                <InputMask
                    type={type}
                    id={field.name}
                    name={field.name}
                    mask="+7 (999) 999-99-99"
                    autoComplete="none"
                    className="custom-field__input gx-mb-0"
                    value={field.value}
                    onChange={onChange}
                    onFocus={() => {
                        if (!isFocused) {
                            setIsFocused(true)
                        }
                        if (!!props.handleFocusField) {
                            props.handleFocusField();
                        }
                    }}
                    onBlur={() => isFocused && setIsFocused(false)}
                    autoFocus={props.isAutoFocus}/>

            )
        }
        if (props.isSelect) {
            return (
                <Select
                    id={field.name}
                    className="custom-field__input gx-mb-0"
                    options={props.opts}
                    onBlur={() => isFocused && setIsFocused(false)}
                    onFocus={() => {
                        if (!isFocused) {
                            setIsFocused(true)
                        }
                        if (!!props.handleFocusField) {
                            props.handleFocusField();
                        }
                    }}
                    onChange={handleChange}/>
                // <Field
                //     name="preCallPeriod"
                //     componentClassName="gx-w-100"
                //     options={PRE_CALL_OPTIONS}
                //     id={field.name}
                //     {...field}
                //     component={AntSelect}
                //     className="custom-field__input gx-mb-0"
                //     onFocus={() => {
                //         if (!isFocused) {
                //             setIsFocused(true)
                //         }
                //         if (!!props.handleFocusField) {
                //             props.handleFocusField();
                //         }
                //     }}
                //     onBlur={() => isFocused && setIsFocused(false)}
                //     onChange={onChange}
                // />
            )
        }
        return (
            <Field
                type={type}
                id={field.name}
                {...field}
                className="custom-field__input gx-mb-0"
                onFocus={() => {
                    if (!isFocused) {
                        setIsFocused(true)
                    }
                    if (!!props.handleFocusField) {
                        props.handleFocusField();
                    }
                }}
                onBlur={() => isFocused && setIsFocused(false)}
                onChange={onChange}
                autoFocus={props.isAutoFocus}
                maxLength={props.maxLength}
            />
        )
    }

    const getObjValue = (obj?: anyObject, key?: string) => {
        if (!obj || !key) return false;
        return key.split('.').reduce((o,i)=> {
            const curObj = (Array.isArray(o) && o.length) ? o[0] : o;
            return (curObj || {})[i]
        }, obj)
    }

    const currentField = getCurrentField();

    return (
        <>
            <div className={classNames("custom-field gx-flex-column", {"is-filled": isFocused || field.value, "is-focused": isFocused, "is-error": !!getObjValue(errors, props.errorName ?? field.name) && (props.disableTouch || getObjValue(touched, field.name))})}>
                <label htmlFor={field.name} className="custom-field__label">{label}</label>
                {currentField}
                {props.suffix ? props.suffix : null}
            </div>
            {!!getObjValue(errors, props.errorName ?? field.name) && (props.disableTouch || getObjValue(touched, field.name)) && <div className="custom-field__error">{getObjValue(errors, props.errorName ?? field.name)}</div>}
        </>
    )
}

export { CustomField };